import axios from "axios";

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

export const callApi = async (methodType, url, apiData, apiHeader) => {
  try {
    const response = await axios({
      method: methodType,
      url: process.env.REACT_APP_API_URL + url,
      data: { ...apiData },
      headers: { ...apiHeader },
    });
    return response;
  } catch (err) {
    return err.response;
  }
};

export const GetHomePageData = async () => {
  try {
    const responsePopularDestinations = await callApi(
      "get",
      "TermsAndPolicies/GetPopularDestinations?Popular=offers",
      {},
      {}
    );

    const responseFQA = await callApi(
      "get",
      "TermsAndPolicies/GetFAQs",
      {},
      {}
    );

    const responsePopularCities = await callApi(
      "get",
      "TermsAndPolicies/GetPopularDestinations?Popular=cities",
      {},
      {}
    );

    const obj = {
      responsePopularDestinations: responsePopularDestinations,
      responseFQA: responseFQA,
      responsePopularCities: responsePopularCities,
    };

    return obj;
  } catch (err) {
    return null;
  }
};

export const checkIsNullOrUndefined = (value) => {
  return value === null || value === undefined ? "" : value;
};

export const getTomorrowAtSixAM = () => {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1); // Add 1 day to the current date
  tomorrow.setHours(6, 0, 0, 0); // Set time to 6 AM

  // Manually format the date as 'YYYY-MM-DDTHH:MM'
  const year = tomorrow.getFullYear();
  const month = String(tomorrow.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
  const day = String(tomorrow.getDate()).padStart(2, "0");
  const hours = String(tomorrow.getHours()).padStart(2, "0");
  const minutes = String(tomorrow.getMinutes()).padStart(2, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

export const getMaxPickupDate = () =>{
  const today = new Date();
  // Create a new date object for three months from today
  const maxDate = new Date();
  maxDate.setMonth(today.getMonth() + 3);

  const year = maxDate.getFullYear();
  const month = String(maxDate.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
  const day = String(maxDate.getDate()).padStart(2, "0");
  const hours = String(maxDate.getHours()).padStart(2, "0");
  const minutes = String(maxDate.getMinutes()).padStart(2, "0");
 // (`${year}-${month}-${day}T${hours}:${minutes}`)
  return `${year}-${month}-${day}T${hours}:${minutes}`
}

export const getTomorrowDate = () => {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1); // Add one day to today's date
  return tomorrow.toISOString().split("T")[0]; // Format to YYYY-MM-DD
};

export const convertDatetoReadFormat = (dateString) => {
  const date = new Date(dateString);

  // Format the date and time
  const optionsDate = { day: "2-digit", month: "short", year: "numeric" };
  const optionsTime = { hour: "numeric", minute: "2-digit", hour12: true };

  const formattedDate = date.toLocaleDateString("en-GB", optionsDate);
  const formattedTime = date
    .toLocaleTimeString("en-GB", optionsTime)
    .replace(/(\d+)(:\d+)?\s?(AM|PM)/, "$1 $3");

  return `${formattedDate}, ${formattedTime}`;
};

export const convertTimestamp = (timestamp) => {
  const date = new Date(timestamp);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
  const year = String(date.getFullYear()).slice(-2); // Get last two digits of the year

  let hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, "0");

  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12; // Convert 0 to 12 for midnight

  return `${day}-${month}-${year} ${hours}:${minutes} ${ampm}`;
};

export const setDOB = (inputDate) => {
  const date = new Date(inputDate);
  const utcDate = new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
  );
  const dateString = utcDate.toISOString().split("T")[0];
  return dateString;
};

export const setUserData = (data) => {
  if (data.userImage === null) {
    return data;
  } else {
    data.userImage = process.env.REACT_APP_API_IMAGE_URL + data.userImage;
    data.dob = setDOB(data.dob);
    return data;
  }
};

export const convertToYYYYMMDD = (dateString) => {
  const dateStr = new Date(dateString);
  const utcDate = new Date(
    Date.UTC(dateStr.getFullYear(), dateStr.getMonth(), dateStr.getDate())
  );
  return utcDate.toISOString().split("T")[0];
};

export const convertToDDMMYYY = (dateString) => {
  // Split the string by hyphens
  const [year, month, day] = dateString.split("-");

  // Rearrange to dd-mm-yyyy format
  return `${day}-${month}-${year}`;
};
