import React, { useEffect, useRef, useState } from "react";
import { useBookNowModal } from "../hooks/useBookNowModal";

import { NotificationManager } from "react-notifications";
import useRazorpay from "react-razorpay";
import { ApiHeaders, CurrentDateWithoutFormat, DefaultDate } from "../common/ConstStates";
import {
  callApi,
  convertTimestamp,
  convertToDDMMYYY,
  convertToYYYYMMDD,
  getTomorrowAtSixAM,
} from "../common/GeneralMethod";
import { useAuth } from "../hooks/useAuth";
import { useBookingData } from "../hooks/useBookingData";
import { usePage } from "../hooks/usePage";
import { useProfileSection } from "../hooks/useProfileSection";
import BookingSuccess from "./SuccessModal";

import { useBookingSelectedDetails } from "../hooks/useBookingSelectedDetails";

const BookNow = ({ setIsBookNow }) => {
  const { page, setPage } = usePage();
  let { bookDetails, setBookDetails } = useBookingSelectedDetails();
  const [Razorpay] = useRazorpay();
  const { showBookNowModal, setBookNowModalState } = useBookNowModal();
  const { bookSelectedDetails, setBookSelectedDetails } =
    useBookingSelectedDetails(); //booking selected details
  const { bookData } = useBookingData(); // compute route selected card data
  const modalRef = useRef(null);
  const { user } = useAuth();
  const [totalAmount, setTotalAmount] = useState(0);
  const [coupenValue, setCoupenValue] = useState("");
  const [coupenAmount, setCoupenAmount] = useState(0)
  const [agreeTerm, setAgreeTerm] = useState(false);
  const { activeTab, setActiveTab } = useProfileSection();
  const [successModal, setSuccessModal] = useState(false);
  const [saveBookingData, setSaveBookingData] = useState({});
  const [isApplyCoupenDisabled, setApplyCoupenDisabled] = useState(false);
  const [advancePayment, setAdvancePayment] = useState(0);
  const [isEditPickUpDate, setIsEditPickUpDate] = useState(false);
  useEffect(() => {
    // console.log("bookdata", bookData);
    setTotalAmount(bookData.totalFare);

    const advance =calculateAdvancePercentage(bookData.totalFare)
      
    setAdvancePayment(advance);
    setSaveBookingData({
      ...bookData,
      extraServiceDescription: "No Pet",
      extraService: "No Luggage",
    });
    setTotalAmount(0)
    setCoupenValue("");
    setCoupenAmount(0)
    //setSuccessModal(true);
  }, [bookData]);

  const calculateAdvancePercentage = (fare)=>{
    return parseFloat(parseFloat(fare) *
    parseFloat(bookData.advancePercentage / 100)).toFixed(2);
  }

  // useEffect(() => {
  //   console.log("booking data", saveBookingData);
  // }, [saveBookingData]);

  // useEffect(() => {
  //   console.log("total c", totalAmount);
  // }, [totalAmount]);
  useEffect(() => {
    // console.log(bookData)
    //setTotalAmount(0)
    // setCoupenValue("");
    // setCoupenAmount(0)
    // console.log('login', user)
    // console.log("save booking data", saveBookingData)
    if (
      showBookNowModal &&
      JSON.stringify(bookSelectedDetails) === "{}" &&
      JSON.stringify(saveBookingData) === "{}"
    ) {
      NotificationManager.error("Error while processing");
    } else {
      // console.log(saveBookingData.totalFare)
      // setTotalAmount(saveBookingData.totalFare)
    }

   // console.log("bookingData", bookSelectedDetails);
  }, [showBookNowModal, bookSelectedDetails]);

  // useEffect(()=>{
  //   // console.log("inside book now modal", showBookNowModal)
  // },[showBookNowModal])

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setBookNowModalState(false); // Close the modal
        setIsBookNow(false);
        setIsEditPickUpDate(false);
        setApplyCoupenDisabled(false);
      }
    };

    if (showBookNowModal) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    // Cleanup the event listener when modal is closed
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [showBookNowModal, setBookNowModalState]);

  const handleExtraLuggageCheckBox = (e) => {
     //calculate advance percentage of extra luggage amount
     const extraLuggageAdvancePercentage = calculateAdvancePercentage(e.target.value)
    if (e.target.checked === true) {
      //set total amount
      const amount = parseFloat(totalAmount) + parseFloat(e.target.value);
      setTotalAmount(amount);
     
      //add percentage amount to advance payment
      const advance = parseFloat(advancePayment) + parseFloat(extraLuggageAdvancePercentage)
      setAdvancePayment(advance.toFixed(2));
      var extraServiceAmt =
        parseFloat(saveBookingData.extraServiceAmount) +
        parseFloat(e.target.value);
      setSaveBookingData({
        ...saveBookingData,
        extraServiceAmount: extraServiceAmt,
        extraService: "Yes Luggage",
      });

      //saveBookingData.extraService = "Yes Luggage"
    } else {
      const amount = parseFloat(totalAmount) - parseFloat(e.target.value);
      setTotalAmount(amount);
      const advance =
      parseFloat(advancePayment) - parseFloat(extraLuggageAdvancePercentage)
      setAdvancePayment(advance.toFixed(2));
      var extraServiceAmt =
        parseFloat(saveBookingData.extraServiceAmount) -
        parseFloat(e.target.value);
      setSaveBookingData({
        ...saveBookingData,
        extraServiceAmount: extraServiceAmt,
        extraService: "No Luggage",
      });
    }
  };

  const handlePetAmountChange = (e) => {
     //calculate advance percentage of pet animal amount
     const extraLuggageAdvancePercentage = calculateAdvancePercentage(e.target.value)
    if (e.target.checked === true) {
      const amount = parseFloat(totalAmount) + parseFloat(e.target.value);
      //setTotalAmount(amount);
      const advance = parseFloat(advancePayment) + parseFloat(extraLuggageAdvancePercentage)
      setAdvancePayment(advance.toFixed(2));

      var extraServiceAmt =
        parseFloat(saveBookingData.extraServiceAmount) +
        parseFloat(e.target.value);
      setSaveBookingData({
        ...saveBookingData,
        extraServiceAmount: extraServiceAmt,
        extraServiceDescription: "Yes Pet",
      });
    } else {
      const amount = parseFloat(totalAmount) - parseFloat(e.target.value);
   //   setTotalAmount(amount);
      const advance =
      parseFloat(advancePayment) - parseFloat(extraLuggageAdvancePercentage)
      setAdvancePayment(advance.toFixed(2));
      var extraServiceAmt =
        parseFloat(saveBookingData.extraServiceAmount) +
        parseFloat(e.target.value);
      setSaveBookingData({
        ...saveBookingData,
        extraServiceAmount: extraServiceAmt,
        extraServiceDescription: "No Pet",
      });
    }
  };
  const handleRefundChange = (e) => {
    if (e.target.checked === true) {
      setTotalAmount(parseFloat(totalAmount) + parseFloat(e.target.value));
    } else {
      setTotalAmount(parseFloat(totalAmount) - parseFloat(e.target.value));
    }
  };

  const applyCoupenClick = async () => {
    //remove coupen
    if(isApplyCoupenDisabled){
      setCoupenValue("")

     
      //calculate amount
      // const totalAmt = totalAmount + parseFloat(coupenAmount);
          
      // setTotalAmount(totalAmt);
       //calculate advance percentage
       let advanceFare = calculateAdvancePercentage(saveBookingData.totalFare)
       //if extra luggage added
       if(saveBookingData.extraService === "Yes Luggage")  {
         const advanceLuggaeAmt = calculateAdvancePercentage(saveBookingData.extraLuggageAmount)
         advanceFare = parseFloat(advanceFare) + parseFloat(advanceLuggaeAmt)
       }
       if(saveBookingData.extraServiceDescription === "Yes Pet")  {
         const advancePetAmt = calculateAdvancePercentage(saveBookingData.petAnimalAmount)
         advanceFare = parseFloat(advanceFare) + parseFloat(advancePetAmt)
       }
       setSaveBookingData({
        ...saveBookingData,
        isCoupenApply: false,
        coupenCode: "",
        promoCode:"0"
      })
       setCoupenAmount(0)
       setAdvancePayment(parseFloat(advanceFare).toFixed(2));
      setApplyCoupenDisabled(false)
      return
    }
    if (coupenValue === "") {
      NotificationManager.error("Please Enter Valid Coupen Code");
      return;
    }

    const response = await callApi(
      "get",
      "Booking/ApplyCoupenCode?coupen=" +
        coupenValue +
        "&PhoneNo=" +
        bookSelectedDetails.userPhone,
      {},
      { UserType: user.userType, "Content-Type": "application/json" }
    );
    // console.log('coupen',response)
    if (response !== undefined && response !== null) {
      const result = response.data;
      if (result.code === 200) {
        if (result.data.length > 0) {

          const data = result.data[0].discountAmount;
          setCoupenAmount(data)
          //apply coupen to total fare
          const totalAmount = parseFloat(saveBookingData.totalFare) - parseFloat(data);
          
          setTotalAmount(totalAmount);
          //calculate advance percentage
          let advanceFare = calculateAdvancePercentage(totalAmount)
          //if extra luggage added
          if(saveBookingData.extraService === "Yes Luggage")  {
            const advanceLuggaeAmt = calculateAdvancePercentage(saveBookingData.extraLuggageAmount)
            advanceFare = parseFloat(advanceFare) + parseFloat(advanceLuggaeAmt)
          }
          if(saveBookingData.extraServiceDescription === "Yes Pet")  {
            const advancePetAmt = calculateAdvancePercentage(saveBookingData.petAnimalAmount)
            advanceFare = parseFloat(advanceFare) + parseFloat(advancePetAmt)
          }
          setAdvancePayment(parseFloat(advanceFare).toFixed(2));
          setApplyCoupenDisabled(true);
          setSaveBookingData({
            ...saveBookingData,
            isCoupenApply: true,
            coupenCode: coupenValue,
            promoCode:data.toString()
          });
        } else {
          NotificationManager.warning("Invalid Coupen Code");
        }
      } else {
        NotificationManager.error("Error while applying coupen");
      }
    } else {
      NotificationManager.error("Error while applying coupen");
    }
  };

  const onChangeAgreeTerm = (e) => {
    setAgreeTerm(e.target.checked);
  };

  const handleClose = () => {
    setSuccessModal(false);
  };

  const handleManageBooking = () => {
    setSuccessModal(false);
    setPage("Profile");
    setActiveTab(2);
    setBookSelectedDetails({});
  };

  const handleBookNow = (e) => {
    e.preventDefault();
    if (agreeTerm === false) {
      NotificationManager.error("Please agree terms");
      return;
    }
    handlePayment1(user);
  };

  const handlePayment1 = async ( user) => {
    // var advancePayment =
    //   parseFloat(amt) * parseFloat(parseInt(bookData.advancePercentage) / 100);

    try {
      //1. Create order
      const orderResponse = await callApi(
        "post",
        "Payment/CreateOrder",
        {
          amount: advancePayment,
          currency: "INR",
          receipt: "receipt123",
        },
        {
          ...ApiHeaders,
        }
      );
     
      if(orderResponse===undefined || orderResponse===null ){
        NotificationManager.error("Cannot process payment")
        return
      }
      const result = orderResponse.data.data;
      if(result===undefined || result===null ){
        NotificationManager.error("Cannot process payment")
        return
      }

      const options = {
        key: result.secret_key,
        amount: result.amount,
        currency: result.currency,
        name: "CABKRO",
        description: "Test Transaction",
        image: "",
        order_id: result.id,
        handler: async (response) => {
          // console.log('response handler',response);
          const verificationResponse = await callApi(
            "post",
            "Payment/capture-payment",
            {
              orderId: result.id,
              paymentId: response.razorpay_payment_id,
              signature: response.razorpay_signature,
              amount: result.amount,
            },
            {
              ...ApiHeaders,
            }
          );
          //  console.log('response capture', verificationResponse)
          if (
            verificationResponse !== undefined &&
            verificationResponse !== null
          ) {
            if (verificationResponse.data.code === 200) {
              // Payment successful call save
              saveBookingData.userId = user.userId;
              saveBookingData.userEmail = user.userEmail;
              saveBookingData.userName =
                user.userFirstName + " " + user.userLastName;
              saveBookingData.vehicleName = saveBookingData.vehicleType;
              saveBookingData.paymentAmountAdvanceOrderId = result.id;
              saveBookingData.paymentAmountAdvance = result.amount;
              saveBookingData.paymentAmountAdvanceTransactionID =
                response.razorpay_payment_id;
              saveBookingData.createdDate = CurrentDateWithoutFormat;
              saveBookingData.bookingStatus = "pending";
              saveBookingData.isActive = true;

              saveBookingData.pickupTime = bookSelectedDetails.pickupTime;
              saveBookingData.dropOffTime = bookSelectedDetails.dropOffTime;

              //  console.log('data send to api', saveBookingData)
              const saveBooking = await callApi(
                "post",
                "Booking/InsertBookingDetail",
                { ...saveBookingData },
                { ...ApiHeaders }
              );

              if (saveBooking !== undefined && saveBooking !== null) {
                if (saveBooking.data.code === 200) {
                  setBookNowModalState(false);
                  setSuccessModal(true);
                  //setBookSelectedDetails({});

                  // setActiveTab(2)
                  // setPage("Profile")
                } else {
                  NotificationManager.error("Error while processing booking.");
                }
              } else {
                NotificationManager.error("Error while processing booking.");
              }
            } else {
              NotificationManager.error(verificationResponse.data.message);
              setBookNowModalState(false);
            }
          } else {
            NotificationManager.error("Error while processing payment");
          }
        },
        prefill: {
          name:
            user !== null ? user.userFirstName + " " + user.userLastName : "",
          email: user !== null ? user.userEmail : "",
          contact: user !== null ? user.phoneNo : "",
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#3399cc",
        },
      };

      const rzpay = new Razorpay(options);

      rzpay.open();
    } catch (error) {
      //console.error('Payment Error:', error);
      //alert("Payment failed!");
      NotificationManager.error("Payment Failed");
    }
  };

  const handlePickupDate = (e) => {
    const defaultDate = getTomorrowAtSixAM();
    const selectedValue = e.target.value
    if(new Date(selectedValue) < new Date(defaultDate)){
     
    }
    else{
      setBookSelectedDetails({
        ...bookSelectedDetails,
        [e.target.name]: e.target.value,
      });
    }
   
    // bookingData.pickupTime = e.target.value;
  };

  return (
    <>
      {JSON.stringify(bookSelectedDetails) !== "{}" &&
      JSON.stringify(saveBookingData) !== "{}" ? (
        <div
          className={`modal fade ${showBookNowModal ? "show d-block" : ""}`}
          id="bookingModal"
          tabIndex="-1"
          aria-labelledby="bookingModalLabel"
          style={{
            backgroundColor: showBookNowModal
              ? "rgba(0, 0, 0, 0.5)"
              : "transparent",
          }}
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content" ref={modalRef}>
              <div className="modal-form">
                <div className="modal-header-custom">
                  <h5 style={{ textAlign: "left" }}>
                    {bookSelectedDetails.bookingType === "oneway"
                      ? "One-Way Trip"
                      : "Round Trip"}{" "}
                    <br />
                    {isEditPickUpDate ? (
                      bookSelectedDetails.bookingType === "oneway" ? (
                        <input
                          type="datetime-local"
                          className="form-control"
                          id="pickup-date"
                          name="pickupTime"
                          min={getTomorrowAtSixAM()}
                          max={bookSelectedDetails.dropOffTime + "T00:00"}
                          onChange={handlePickupDate}
                          value={bookSelectedDetails.pickupTime}
                          defaultValue={getTomorrowAtSixAM()}
                          style={{ margin: "2px" }}
                        />
                      ) : (
                        <div className="d-flex">
                          <input
                            type="datetime-local"
                            className="form-control"
                            id="pickup-date"
                            name="pickupTime"
                            min={getTomorrowAtSixAM()}
                            max={bookSelectedDetails.dropOffTime + "T00:00"}
                            onChange={handlePickupDate}
                            value={bookSelectedDetails.pickupTime}
                            defaultValue={getTomorrowAtSixAM()}
                            style={{ margin: "2px" }}
                          />
                          <input
                            type="date"
                            className="form-control"
                            id="dropOffTime-date"
                            name="dropOffTime"
                            min={convertToYYYYMMDD(
                              bookSelectedDetails.pickupTime
                            )}
                            onChange={handlePickupDate}
                            value={bookSelectedDetails.dropOffTime}
                            style={{ margin: "2px" }}
                          />
                        </div>
                      )
                    ) : (
                      <small>
                        {bookSelectedDetails.bookingType === "oneway"
                          ? convertTimestamp(bookSelectedDetails.pickupTime)
                          : convertTimestamp(bookSelectedDetails.pickupTime) +
                            " to " +
                            convertToDDMMYYY(bookSelectedDetails.dropOffTime)}
                        {/* {convertTimestamp(bookSelectedDetails.pickupTime)} */}
                      </small>
                    )}
                  </h5>
                  {/* <h5>
                    {bookingData.bookingType ==="oneway" ? "One-Way Trip": "Round Trip"}  <br />
                    <small>{convertTimestamp(bookingData.pickupTime)}</small>
                  </h5> */}

                  <i
                    className="bi bi-pencil edit-icon"
                    onClick={() => setIsEditPickUpDate(true)}
                  ></i>
                </div>
                <div className="modal-body">
                  <form>
                    <div className="row">
                      <div className="col-md-6">
                        <label htmlFor="phone" className="form-label">
                          Phone
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="phone"
                          value={bookSelectedDetails.userPhone}
                          placeholder="+91 7000000000"
                          readOnly
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="name" className="form-label">
                          Your Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          value={
                            user !== null
                              ? user.userFirstName + " " + user.userLastName
                              : ""
                          }
                          placeholder="Ashish"
                          readOnly
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="email" className="form-label">
                          Email
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          value={user !== null ? user.userEmail : ""}
                          placeholder="ashish234@gmail.com"
                          readOnly
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="pickup-address" className="form-label">
                          Pickup Address
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="pickup-address"
                          placeholder="Deory"
                          value={bookSelectedDetails.pickupLocation}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="mb-3">
                      <label className="form-label">
                        Add On Service (Optional)
                      </label>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={saveBookingData.extraLuggageAmount}
                          id="luggage"
                          onChange={handleExtraLuggageCheckBox}
                          checked={
                            saveBookingData.extraService !== "No Luggage"
                              ? true
                              : false
                          }
                        />
                        <label className="form-check-label" htmlFor="luggage">
                          {"Assured luggage space for Rs. " +
                            saveBookingData.extraLuggageAmount}
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="pet"
                          value={saveBookingData.petAnimalAmount}
                          onChange={handlePetAmountChange}
                          checked={
                            saveBookingData.extraServiceDescription !== "No Pet"
                              ? true
                              : false
                          }
                        />
                        <label className="form-check-label" htmlFor="pet">
                          {"Pet Allowed for Rs. " +
                            saveBookingData.petAnimalAmount}
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="refundable"
                          value={saveBookingData.refundAmount}
                          onChange={handleRefundChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="refundable"
                        >
                          {"Refundable booking for Rs. " +
                            saveBookingData.refundAmount}
                        </label>
                      </div>
                    </div>
                    <div className="apply-coupon">
                      <input
                        type="text"
                        className="form-control"
                        id="coupon"
                        placeholder="Enter your coupon"
                        value={coupenValue}
                        onChange={(e) => setCoupenValue(e.target.value)}
                        disabled={isApplyCoupenDisabled}
                      />
                      <button
                        type="button"
                        className="btn btn-outline-primary"
                        onClick={applyCoupenClick}
                        
                      >
                        {isApplyCoupenDisabled ? "Remove" :"Apply"}
                      </button>
                    </div>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="terms"
                        onChange={onChangeAgreeTerm}
                        required
                        checked={agreeTerm}
                      />
                      <label className="form-check-label" htmlFor="terms">
                        I agree with{" "}
                        <a className="text-primary">
                          Terms of Use & Cancellation Policy
                        </a>
                        .
                      </label>
                    </div>
                    <button className="btn btn-primary" onClick={handleBookNow}>
                      {"Pay ₹ " + advancePayment + " advance & Book"}
                    </button>
                  </form>
                </div>
                <div className="modal-footer-custom">
                  <p>We will send booking details via SMS and Email.</p>
                  <p>
                    Please pay balance payment directly to the driver during the
                    trip.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <BookingSuccess
            show={successModal}
            handleClose={handleClose}
            handleManageBooking={handleManageBooking}
          ></BookingSuccess>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default BookNow;
