import React, { useState } from "react";
import { useLoading } from "../hooks/useLoading";
import { callApi } from "../common/GeneralMethod";
import { ApiHeaders } from "../common/ConstStates";
import { NotificationManager } from "react-notifications";
import { useAuth } from "../hooks/useAuth";
import { usePage } from "../hooks/usePage";
import DeleteConfirmationModal from "./DeleteConfirmationModal";

const DeleteAccountCard = () => {
  const { user, logout } = useAuth();
  const { page, setPage } = usePage();
  const [isChecked, setIsChecked] = useState(false);
  const [showConModal, setShowConModal] = useState(false);
  const { startLoading, stopLoading } = useLoading();
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleClose = () => {
    setShowConModal(false);
  };

  const handleDeleteUser = async (e) => {
    if (!isChecked) {
      return;
    }
    startLoading();
    const response = await callApi(
      "get",
      "Auth/DeactivateUser?UserId=" + user.userId,
      {},
      { ...ApiHeaders }
    );
    if (response !== undefined && response !== null) {
      if (response.data.code === 200) {
        logout();
        setPage("Home");
        stopLoading();
        setShowConModal(false);
        NotificationManager.success("Account Deleted Successfully");
      } else {
        NotificationManager.error("Error while processing request");
      }
      stopLoading();
    } else {
      NotificationManager.error("Error while processing request");
      stopLoading();
    }
  };

  return (
    <div className="col-lg-8 col-xl-9">
      <div className="card border">
        {/* Card header */}
        <div className="card-header border-bottom">
          <h4 className="card-header-title">Delete Account</h4>
        </div>

        {/* Card body */}
        <div className="card-body">
          <h6>Before you go...</h6>
          <ul>
            <li>
              Take a backup of your data <a>Here</a>
            </li>
            <li>If you delete your account, you will lose all your data.</li>
          </ul>
          <div className="form-check form-check-md my-4">
            <input
              className="form-check-input"
              type="checkbox"
              value={isChecked}
              id="deleteaccountCheck"
              onChange={handleCheckboxChange}
            />
            <label className="form-check-label" htmlFor="deleteaccountCheck">
              Yes, I'd like to delete my account
            </label>
          </div>
          <a className="btn btn-success-soft btn-sm mb-2 mb-sm-0">
            Keep my account
          </a>
          <button
            style={{ marginLeft: "10px" }}
            className="btn btn-danger btn-sm mb-0"
            disabled={!isChecked}
            onClick={() => setShowConModal(true)}
          >
            Delete my account
          </button>
        </div>
        {/* Card body END */}
      </div>
      <DeleteConfirmationModal
        message={"Are you sure you want to delete your account permanently?"}
        show={showConModal}
        handleClose={handleClose}
        handleDeleteUser={handleDeleteUser}
      ></DeleteConfirmationModal>
    </div>
  );
};

export default DeleteAccountCard;
