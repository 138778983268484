import React, { useEffect, useState } from "react";
import Header from "../common/Header";

import PopularDestinations from "./PopularDestinations";
import PartnerWithUs from "./PartnerWithUs";
import AppDownloadSection from "./AppDownloadSection";
import FAQAccordion from "./FAQAccordion";
import Footer from "../common/Footer";
import BackToTop from "../common/BackToTop";
import MainBanner from "./MainBanner";
import WhyChooseUs from "./WhyChooseUs";
import Aboutus from "../aboutus/Aboutus";
import Contact from "../ContactUs/Contact";
import UserProfile from "../Profile/UserProfile";
import { GetHomePageData, scrollToTop } from "../common/GeneralMethod";
import { useLoading } from "../hooks/useLoading";
import { NotificationManager } from "react-notifications";
import popDestination from "../images/sliderImg.webp";
import { usePage } from "../hooks/usePage";
import Policy from "../Components/Policy";
import { ApiErrorMessage } from "../common/ConstStates";
import TravelInfo from "./TravelInfo";
import { useLocation } from "react-router-dom";
import { useInternetConnection } from "../hooks/useInternetConnection";
import InternetConnectionModal from "../common/InternetConnectionModal";
import Loader from "../Components/Loader";
function Home() {
  const { startLoading, stopLoading } = useLoading();
  const { page, setPage } = usePage();
  const { show, setShow } = useInternetConnection();
  const [popularDestinations, setPopularDestinations] = useState([]);
  const [faqs, setFaqs] = useState([]);
  const [popularCities, setPopularCities] = useState([]);
  const [popularCitiesNames, setPopularCitiesNames] = useState([]);

  useEffect(() => {
    if (page === "Home") {
      getHomePageData();
    }
    scrollToTop();
  }, [page]);

  const getHomePageData = async () => {
    startLoading();
    const response = await GetHomePageData();

    if (response !== null && response !== undefined) {
      // Popular destinations
      const popularDes = response.responsePopularDestinations;
      // FAQ
      const FAQ = response.responseFQA;
      // Popular cities
      // const popCities = response.responsePopularCities;

      //Check all three has +ve response
      debugger;
      if (
        popularDes !== undefined &&
        popularDes !== null &&
        FAQ !== null &&
        FAQ !== undefined 
        // &&
        // popCities !== null &&
        // popCities !== undefined
      ) {
        //check all three has 200 status code
        if (
          popularDes.data.code === 200 &&
          FAQ.data.code === 200 
          // &&
          // popCities.data.code === 200
        ) {
          //set popular destinations
          const arr = [];
          popularDes.data.data.map((data, index) => {
            arr.push({ ...data, imgSrc: popDestination });
          });
          setPopularDestinations([...arr]);

          //set FAQ
          setFaqs([...FAQ.data.data]);

          //set popular cities
          // const result = popCities.data.data;
          // const uniqueNames = [
          //   ...new Set(result.map((item) => item.pickupCity)),
          // ];
          // setPopularCities([...result]);
          // setPopularCitiesNames([...uniqueNames]);
        } else {
          setPopularDestinations([]);
          setFaqs([]);
          // setPopularCities([]);
          // setPopularCitiesNames([]);
          setShow(true);
        }
      } else {
        setPopularDestinations([]);
        setFaqs([]);
        // setPopularCities([]);
        // setPopularCitiesNames([]);
        setShow(true);
      }
    }
    stopLoading();
  };
  return (
    <div>
      <Header phoneNo={""}></Header>
      {/* When Home Clicked */}
      {page === "Home" && (
        <div>
          <MainBanner
            popularCities={popularCities}
            popularCitiesNames={popularCitiesNames}
          ></MainBanner>

          <WhyChooseUs></WhyChooseUs>

          <PopularDestinations
            sliderItems={popularDestinations}
          ></PopularDestinations>

          <PartnerWithUs></PartnerWithUs>

          <AppDownloadSection></AppDownloadSection>

          <FAQAccordion faqs={faqs}></FAQAccordion>
        </div>
      )}

      {/* About Us Click */}
      {page === "About Us" && <Aboutus></Aboutus>}

      {/* Contact Us Click*/}
      {page === "Contact Us" && <Contact></Contact>}
      {page === "Profile" && <UserProfile></UserProfile>}
      {page === "PrivacyPolicy" && (
        <Policy
          apiName="TermsAndPolicies/PrivacyPolicy"
          heading="Privacy Policy"
        ></Policy>
      )}

      {page === "TermsAndPolicies" && (
        <Policy
          apiName="TermsAndPolicies/GetTermsAndUses"
          heading="Terms & Conditions"
        ></Policy>
      )}

      {page === "CancellationPolicy" && (
        <Policy
          apiName="TermsAndPolicies/CancellationPolicy"
          heading="Refund Policy"
        ></Policy>
      )}

      {page === "travelinfo" && (
        <div>
          <MainBanner
            popularCities={popularCities}
            popularCitiesNames={popularCitiesNames}
          ></MainBanner>
          <TravelInfo></TravelInfo>
        </div>
      )}

      <Footer
      
      ></Footer>

      <BackToTop></BackToTop>
      <Loader></Loader>
      <InternetConnectionModal></InternetConnectionModal>
    </div>
  );
}

export default Home;
