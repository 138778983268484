import React from "react";
import mainLogo from "../images/logo.webp";
import { usePage } from "../hooks/usePage";
const LogoContent = () => {
  const { page, setPage } = usePage();
  return (
    <div className="navbar-brand">
      <img
        id="cloneLogo"
        className="light-mode-item navbar-brand-item"
        src={mainLogo}
        alt="logo"
        style={{ cursor: "pointer" }}
        onClick={() => setPage("Home")}
      />
      <img
        className="dark-mode-item navbar-brand-item"
        src={mainLogo}
        alt="logo"
        style={{ cursor: "pointer" }}
        onClick={() => setPage("Home")}
      />
    </div>
  );
};

export default LogoContent;
