import { Navigate, Outlet } from "react-router-dom";

import { useBookingSelectedDetails } from "./hooks/useBookingSelectedDetails";
const ProtectedRoutes = () => {
  let { bookSelectedDetails, setBookSelectedDetails } =
    useBookingSelectedDetails();
  return JSON.stringify(bookSelectedDetails) !== "{}" ? (
    <Outlet />
  ) : (
    <Navigate to="/" />
  );
};

export default ProtectedRoutes;
