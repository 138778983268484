import { createContext, useState, useContext } from "react";

const BookingSelectedDetailsContext = createContext();

export const BookingSelectedDetaisProvider = ({ children }) => {
  const [bookSelectedDetails, setBookSelectedDetails] = useState({});

  return (
    <BookingSelectedDetailsContext.Provider
      value={{ bookSelectedDetails, setBookSelectedDetails }}
    >
      {children}
    </BookingSelectedDetailsContext.Provider>
  );
};

export const useBookingSelectedDetails = () => {
  return useContext(BookingSelectedDetailsContext);
};
