import React from 'react';
import partnerWithUs from '../images/earner-illustra.webp'

const PartnerWithUs = () => {
  return (
    <section className="pt-0 pt-md-5">
      <div className="container">
        {/* Title */}
        <div className="row g-4 justify-content-between align-items-center">
          <div className="col-lg-5">
            <div className="card shadow text-center align-items-center position-relative">
              {/* Image */}
              <figure className="position-absolute bottom-0 end-0 d-none d-md-block mb-n5 me-n4">
                <svg height="400" className="fill-primary opacity-2" viewBox="0 0 340 340">
                  {[...Array(400)].map((_, i) => {
                    const cx = (i % 17) * 24.2 + 2.2;
                    const cy = Math.floor(i / 17) * 24.2 + 2.2;
                    return <circle key={i} cx={cx} cy={cy} r="2.2" />;
                  })}
                </svg>
              </figure>
              <img
                src={partnerWithUs}
                className="fluid-img rounded-3 position-relative shadow"
                alt=""
                style={{ height: '500px', objectFit: 'cover' }}
              />
            </div>
          </div>
          <div className="col-lg-6">
            <h3 className="my-3">Partner With Us</h3>
            <p>
            Join us and start earning on your schedule with flexible options for rides. Whether you prefer using your own car or renting one through our program, we offer everything you need to get started. Drive your way to extra income today!
            </p>
            <div className="actions">
              <a  className="btn btn-primary mb-0">
                Get Started
              </a>
              <a href="" className="text-dark fw-normal ms-2">
                Already have an account? Sign in
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PartnerWithUs;
