import React, { useEffect, useState } from "react";
import {
  convertToYYYYMMDD,
  getMaxPickupDate,
  getTomorrowAtSixAM,
  getTomorrowDate,
} from "../common/GeneralMethod";


const DatePickerModal = ({
  isVisible,
  closeDatePickerModal,
  pickUpDateHandler,
  dropDateHandler,
  getPriceBtnHandler,
  data,
}) => {
  const [defaultDate, setDefaultDate] = useState("");
  const [maxPickupDate, setMaxPickupDate] = useState("")
  const [isDropDateShow, setIsDropDateShow] = useState(false);

  // Function to get tomorrow's date at 6 AM

  useEffect(() => {
    const defaultDate = getTomorrowAtSixAM();
    const maxDate = getMaxPickupDate()
    setMaxPickupDate(maxDate)
    setDefaultDate(defaultDate);
    pickUpDateHandler(defaultDate);
  }, []);

  useEffect(() => {
    if (data.bookingType === process.env.REACT_APP_BOOKING_TYPE_ROUND_WAY) {
      setIsDropDateShow(true);
    } else {
      setIsDropDateShow(false);
    }
  }, [data]);

  const handlePickupDate = (e) => {
    const selectedValue = e.target.value
    if(new Date(selectedValue) < new Date(defaultDate)){
     
    }
    else{
      pickUpDateHandler(e.target.value);
      // console.log(e.target.value)
    }
    
  };

  const handleDropDate = (e) => {
    dropDateHandler(e.target.value);
    //console.log(e.target.value)
  };
  const handleGetPriceClick = (e) => {
    e.preventDefault();
    getPriceBtnHandler();
  };
  return (
    <div>
      {isVisible ? (
        <>
          <div
            className="modal fade show d-block "
            id="pickupDropModal"
            tabIndex="-1"
            aria-labelledby="pickupDropModalLabel"
            aria-modal="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="pickupDropModalLabel">
                    Pickup & Drop-off Details
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    aria-label="Close"
                    onClick={closeDatePickerModal}
                  ></button>
                </div>
                <div className="modal-body">
                  <form>
                    <div className="form-group">
                      <label htmlFor="pickup-date">Pickup Date</label>
                      <input
                        type="datetime-local"
                        className="form-control"
                        id="pickup-date"
                        name="pickup-date"
                        min={defaultDate}
                        onChange={handlePickupDate}
                        defaultValue={defaultDate}
                        value={data.pickupTime}
                        max={maxPickupDate}
                        required
                      />
                    </div>
                    <div className={isDropDateShow ? "form-group" : "d-none"}>
                      <label htmlFor="drop-date">Drop-off Date</label>
                      <input
                        type="date"
                        className="form-control"
                        id="drop-date"
                        name="drop-date"
                        min={convertToYYYYMMDD(data.pickupTime)}
                        value={data.dropOffTime}
                        onChange={handleDropDate}
                      />
                    </div>
                    <button
                      className="btn btn-primary w-100"
                      onClick={handleGetPriceClick}
                    >
                      Get price
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-backdrop fade show"></div>
        </>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default DatePickerModal;
