export const ApiHeaders = {
  UserType: process.env.REACT_APP_USER_TYPE,
  "Content-Type": "application/json",
};

export const ApiHeader = {
  "Content-Type": "application/json",
};

export const indianPhoneRegex = /^[6-9]\d{9}$/;

export const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const DefaultDate = "1900-01-01T00:00:00";

//2024-09-17T00:00:00
export const CurrentDateWithoutFormat = new Date();

//2024-09-17
export const CurrentDate = new Date().toISOString().split("T")[0];

//2024-09-17T00:00:00
export const CurrentDateTime = new Date().toISOString().slice(0, 16);

//today max date : 2024-09-17
export const MaxTodayDate = new Date().toISOString().split("T")[0];

export const ApiErrorMessage =
  "An error occurred while processing the request.";

export const ValidationErrorMessage = "Please enter mandatory fields";

export const ContactUsFormData = {
  cU_id: 0,
  phoneNo: "",
  emailId: "",
  senderName: "",
  subject: "",
  message: "",
  createdDate: new Date().toISOString(),
  replyDate: DefaultDate,
  replyMessage: "",
  replyBy: 0,
};
