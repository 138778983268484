import React, { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import {
  BsCalendar,
  BsCarFrontFill,
  BsClock,
  BsCurrencyRupee,
  BsFuelPump,
  BsPersonDash,
  BsPerson as BsPersonIcon,
  BsPhone,
  BsPhoneFill,
  BsVr,
} from "react-icons/bs";
import { BiSolidCoupon } from "react-icons/bi";
import { GiPathDistance } from "react-icons/gi";
import { NotificationManager } from "react-notifications";
import { ApiHeaders } from "../common/ConstStates";
import { callApi, convertDatetoReadFormat } from "../common/GeneralMethod";
import { useBookingManageData } from "../hooks/useManageBookingData";
import { useProfileSection } from "../hooks/useProfileSection";
import DeleteConfirmationModal from "./DeleteConfirmationModal";

const ManageBooking = () => {
  const { eachbookingData, setEachBookingData } = useBookingManageData();

  const { activeTab, setActiveTab } = useProfileSection();
  const [showConModal, setShowConModal] = useState(false);

  const handleClose = () => {
    setShowConModal(false);
  };

  const handleCancelBooking = async () => {
    const response = await callApi(
      "get",
      "Booking/CancelBooking?bookingId=" + eachbookingData.booking_id,
      {},
      { ...ApiHeaders }
    );

    if (response !== undefined && response !== null) {
      if (response.data.code === 200) {
        NotificationManager.success("Booking Cancelled");
        setActiveTab(2);
      } else {
        NotificationManager.error("Error while processing request");
      }
    } else {
      NotificationManager.error("Error while processing request");
    }
  };

  return (
    <div className="col-lg-8 col-xl-9 ps-xl-5">
      <style>
        {`
                                    .booking_for ul.btype {
                                        list-style: none;
                                        padding-left: 0;
                                        display: flex;
                                        gap: 1.5rem;
                                        align-items: center;
                                    }
                                    .booking_for ul.btype li {
                                        position: relative;
                                    }
                                    .booking_for ul.btype li:after {
                                        content: "\\f135";
                                        font-family: bootstrap-icons !important;
                                        font-size: 1.4rem;
                                        position: absolute;
                                        top: -3px;
                                        font-weight: 500;
                                    }
                                    .booking_for ul.btype li:last-child::after {
                                        display: none;
                                    }
                                    .booking-location {
                                        list-style: none;
                                        padding-left: 0;
                                        display: flex;
                                        justify-content: space-between;
                                        align-items: center;
                                    }
                                `}
      </style>
      {JSON.stringify(eachbookingData) !== "{}" ? (
        <Card border="light" className="bg-transparent">
          <Card.Header className="bg-transparent border-bottom">
            <h5 className="card-header-title">Manage Booking</h5>
          </Card.Header>
          <Card.Body className="p-0 booking_info">
            <div className="booking_for p-3 position-relative">
              <ul className="btype mb-0">
                <li>
                  <a className="badge text-bg-warning fw-bold">Out Station</a>
                </li>
                <li>
                  <a className="badge text-bg-warning fw-bold">
                    {eachbookingData.bookingType ===
                      process.env.REACT_APP_BOOKING_TYPE_ONE_WAY
                      ? "One Way"
                      : "Round Trip"}
                  </a>
                </li>
              </ul>
              <div className="row justify-content-between text-start mb-2 mt-3">
                <div className="col-md-12">
                  <ul className="booking-location">
                    <li>
                      <div>
                        <span className="badge text-bg-success mb-1">
                          Pickup
                        </span>
                        <h6>{eachbookingData.pickupLocation}</h6>
                      </div>
                    </li>
                    <li>
                      <span className="badge text-bg-danger">Drop</span>
                      <h6>{eachbookingData.dropOffLocation}</h6>
                    </li>
                  </ul>
                </div>

                {/* Booking Details */}
                <div className="col-lg-6">
                  <ul className="list-group list-group-borderless">
                    <li className="list-group-item d-sm-flex justify-content-between align-items-center">
                      <span className="mb-0">
                        <BsVr className="fa-fw me-2" />
                        Booking ID:
                      </span>
                      <span className="h6 fw-normal mb-0">
                        {eachbookingData.other2}
                      </span>
                    </li>
                    <li className="list-group-item d-sm-flex justify-content-between align-items-center">
                      <span className="mb-0">
                        <BsCalendar className="fa-fw me-2" />
                        Booking Date:
                      </span>
                      <span className="h6 fw-normal mb-0">
                        {convertDatetoReadFormat(eachbookingData.createdDate)}
                      </span>
                    </li>
                    <li className="list-group-item d-sm-flex justify-content-between align-items-center">
                      <span className="mb-0">
                        <BsCalendar className="fa-fw me-2" />
                        Pick Up Date:
                      </span>
                      <span className="h6 fw-normal mb-0">
                        {convertDatetoReadFormat(eachbookingData.pickupTime)}
                      </span>
                    </li>

                  </ul>
                </div>
                <div className="col-lg-6">
                  <ul className="list-group list-group-borderless">
                    <li className="list-group-item d-sm-flex justify-content-between align-items-center">
                      <span className="mb-0">
                        <BsPersonIcon className="fa-fw me-2" />
                        Booked by:
                      </span>
                      <span className="h6 fw-normal mb-0">
                        {eachbookingData.userName}
                      </span>
                    </li>
                    <li className="list-group-item d-sm-flex justify-content-between align-items-center">
                      <span className="mb-0">
                        <BsPersonIcon className="fa-fw me-2" />
                        Booking Status:
                      </span>
                      <span className="h6 fw-normal mb-0">
                        {(eachbookingData.bookingStatus).toUpperCase()}
                      </span>
                    </li>
                    {eachbookingData.bookingType ===
                      process.env.REACT_APP_BOOKING_TYPE_ONE_WAY
                      ? null
                      :  <li className="list-group-item d-sm-flex justify-content-between align-items-center">
                      <span className="mb-0">
                        <BsCalendar className="fa-fw me-2" />
                        Drop Date:
                      </span>
                      <span className="h6 fw-normal mb-0">
                        {convertDatetoReadFormat(eachbookingData.dropOffTime)}
                      </span>
                    </li>}
                   

                  </ul>
                </div>
                {eachbookingData.bookingType ===
                  process.env.REACT_APP_BOOKING_TYPE_ONE_WAY
                  ? null
                  : <> <p className="fw-bold p-2 m-0">Inter Locations</p><div >
                  <ul className="list-group list-group-borderless">
                    {
                      [...Array(12)].map((data,index)=>(

                        eachbookingData["interLocation" + (index+1)] !== ""?  <li className="list-group-item d-sm-flex justify-content-between align-items-center">
                        <span className="mb-0">
                          <GiPathDistance className="fa-fw me-2" />
                          {"Inter Location "+ (index+1)+":"}
                        </span>
                        <span className="h6 fw-normal mb-0">
                          {eachbookingData["interLocation" + (index+1)]}
                        </span>
                      </li>:null
                       

                      ))
                    }
                  

                  
                  
               
                  </ul>
                </div></>}

               
                <p className="fw-bold p-2 m-0">Booking Details</p>
                {/* Booking Details */}
                <div className="col-lg-6">
                  <ul className="list-group list-group-borderless">
                    <li className="list-group-item d-sm-flex justify-content-between align-items-center">
                      <span className="mb-0">
                        <GiPathDistance className="fa-fw me-2" />
                        Total Distance:
                      </span>
                      <span className="h6 fw-normal mb-0">
                        {eachbookingData.tripDistance + " KM"}
                      </span>
                    </li>
                    {/* <li className="list-group-item d-sm-flex justify-content-between align-items-center">
                      <span className="mb-0">
                      <BsCurrencyRupee className="fa-fw me-2" />
                        Fare /KM:
                      </span>
                      <span className="h6 fw-normal mb-0">
                      <BsCurrencyRupee  />
                        {eachbookingData.ratePerKm}
                      </span>
                    </li> */}

                    <li className="list-group-item d-sm-flex justify-content-between align-items-center">
                      <span className="mb-0">
                        <BsCurrencyRupee className="fa-fw me-2" />
                        Toll Tax:
                      </span>
                      <span className="h6 fw-normal mb-0">

                        {eachbookingData.tollTaxType === "Included" ? eachbookingData.tollTaxType : eachbookingData.tollTaxAmount}
                      </span>
                    </li>
                    <li className="list-group-item d-sm-flex justify-content-between align-items-center">
                      <span className="mb-0">
                        <BsCurrencyRupee className="fa-fw me-2" />
                        Night Charges:
                      </span>
                      <span className="h6 fw-normal mb-0">

                        {eachbookingData.nightChargesType === "Included" ? eachbookingData.nightChargesType : <><BsCurrencyRupee />{eachbookingData.nightChargesAmount}</>}
                      </span>
                    </li>

                    <li className="list-group-item d-sm-flex justify-content-between align-items-center">
                      <span className="mb-0">
                        <BsCurrencyRupee className="fa-fw me-2" />
                        Extra Luggage:
                      </span>
                      <span className="h6 fw-normal mb-0">
                        <BsCurrencyRupee />
                        {eachbookingData.extraService === "Yes Luggage" ? eachbookingData.extraLuggageAmount : 0}
                      </span>
                    </li>
                    {eachbookingData.isCoupenApply ? <li className="list-group-item d-sm-flex justify-content-between align-items-center">
                      <span className="mb-0">
                        <BiSolidCoupon className="fa-fw me-2" />
                        Coupen Applied:
                      </span>
                      <span className="h6 fw-normal mb-0">
                        {eachbookingData.coupenCode}
                      </span>
                    </li> : null}
                    <li className="list-group-item d-sm-flex justify-content-between align-items-center">
                      <span className="mb-0">
                        <BsCurrencyRupee className="fa-fw me-2" />
                        Total Price:
                      </span>
                      <span className="h6 fw-normal mb-0">
                        <BsCurrencyRupee />
                        {(parseFloat(eachbookingData.totalFare) + parseFloat(eachbookingData.extraServiceAmount)) - parseFloat(eachbookingData.promoCode ||0  )}
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-6">
                  <ul className="list-group list-group-borderless">

                    <li className="list-group-item d-sm-flex justify-content-between align-items-center">
                      <span className="mb-0">
                        <BsClock className="fa-fw me-2" />
                        Estimate Time:
                      </span>
                      <span className="h6 fw-normal mb-0">
                        {eachbookingData.timeDurationInHour + " Hr"}
                      </span>
                    </li>
                    {/* <li className="list-group-item d-sm-flex justify-content-between align-items-center">
                      <span className="mb-0">
                      <BsCurrencyRupee className="fa-fw me-2" />
                        {"GST Amount (" + (eachbookingData.gstRate) + "%)"}
                      </span>
                      <span className="h6 fw-normal mb-0">
                      <BsCurrencyRupee  />
                        {eachbookingData.gstAmount}
                      </span>
                    </li> */}
                    <li className="list-group-item d-sm-flex justify-content-between align-items-center">
                      <span className="mb-0">
                        <BsCurrencyRupee className="fa-fw me-2" />
                        Driver Charges:
                      </span>
                      <span className="h6 fw-normal mb-0">

                        {eachbookingData.driverChargesType === "Included" ? eachbookingData.driverChargesType : eachbookingData.driverChargesAmount}
                      </span>
                    </li>
                    <li className="list-group-item d-sm-flex justify-content-between align-items-center">
                      <span className="mb-0">
                        <BsCurrencyRupee className="fa-fw me-2" />
                        Distance Fare:
                      </span>
                      <span className="h6 fw-normal mb-0">
                        <BsCurrencyRupee />
                        {parseFloat(eachbookingData.fare) + parseFloat(eachbookingData.gstAmount)}
                      </span>
                    </li>

                    <li className="list-group-item d-sm-flex justify-content-between align-items-center">
                      <span className="mb-0">
                        <BsCurrencyRupee className="fa-fw me-2" />
                        Pet Animal Amount:
                      </span>
                      <span className="h6 fw-normal mb-0">
                        <BsCurrencyRupee />
                        {eachbookingData.extraServiceDescription === "Yes Pet" ? eachbookingData.petAnimalAmount : 0}
                      </span>
                    </li>
                    {eachbookingData.isCoupenApply ? <li className="list-group-item d-sm-flex justify-content-between align-items-center">
                      <span className="mb-0">
                        <BsCurrencyRupee className="fa-fw me-2" />
                        Coupen Amount:
                      </span>
                      <span className="h6 fw-normal mb-0">
                        <BsCurrencyRupee />
                        {eachbookingData.promoCode}
                      </span>
                    </li> : null}
                    <li className="list-group-item d-sm-flex justify-content-between align-items-center">
                      <span className="mb-0">
                        <BsCurrencyRupee className="fa-fw me-2" />
                        Advance Amount:
                      </span>
                      <span className="h6 fw-normal mb-0">
                        <BsCurrencyRupee />
                        {eachbookingData.paymentAmountAdvance}
                      </span>
                    </li>
                  </ul>
                </div>


                <p className="fw-bold p-2 m-0">Vehicle Details</p>
                {/* Vehicle Details */}
                <div className="col-lg-6">
                  <ul className="list-group list-group-borderless">
                    <li className="list-group-item d-sm-flex justify-content-between align-items-center">
                      <span className="mb-0">
                        <BsCarFrontFill className="fa-fw me-2" />
                        Vehicle Name:
                      </span>
                      <span className="h6 fw-normal mb-0">
                        {eachbookingData.vehicleName}
                      </span>
                    </li>
                    <li className="list-group-item d-sm-flex justify-content-between align-items-center">
                      <span className="mb-0">
                        <BsCarFrontFill className="fa-fw me-2" />
                        Vehicle Type:
                      </span>
                      <span className="h6 fw-normal mb-0">
                        {eachbookingData.vehicleType}
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-6">
                  <ul className="list-group list-group-borderless">
                    <li className="list-group-item d-sm-flex justify-content-between align-items-center">
                      <span className="mb-0">
                        <BsFuelPump className="fa-fw me-2" />
                        Vehicle Fuel Type:
                      </span>
                      <span className="h6 fw-normal mb-0">
                        {eachbookingData.vehicleFuelType}
                      </span>
                    </li>
                    <li className="list-group-item d-sm-flex justify-content-between align-items-center">
                      <span className="mb-0">
                        <BsPersonDash className="fa-fw me-2" />
                        Seat Count:
                      </span>
                      <span className="h6 fw-normal mb-0">
                        {eachbookingData.vehicleSeaterCount}
                      </span>
                    </li>
                  </ul>
                </div>

                <p className="fw-bold p-2 m-0">Driver Details</p>
                {/* Driver Details */}
                <div className="col-lg-6">
                  <ul className="list-group list-group-borderless">
                    <li className="list-group-item d-sm-flex justify-content-between align-items-center">
                      <span className="mb-0">
                        <BsPhoneFill className="fa-fw me-2" />
                        Driver Phone No:
                      </span>
                      <span className="h6 fw-normal mb-0">
                        {eachbookingData.driverPhone}
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-6">
                  <ul className="list-group list-group-borderless">
                    <li className="list-group-item d-sm-flex justify-content-between align-items-center">
                      <span className="mb-0">
                        <BsPersonIcon className="fa-fw me-2" />
                        Driver Name:
                      </span>
                      <span className="h6 fw-normal mb-0">
                        {eachbookingData.driverName}
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-12 text-end">
                  <Button
                    className="btn btn-danger mb-0"
                    onClick={() => setShowConModal(true)}
                  >
                    Cancel Booking
                  </Button>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      ) : null}

      <DeleteConfirmationModal
        message={"Are you sure you want to cancel booking?"}
        show={showConModal}
        handleClose={handleClose}
        handleDeleteUser={handleCancelBooking}
      ></DeleteConfirmationModal>
    </div>
  );
};

export default ManageBooking;
