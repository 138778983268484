import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";

import { useEffect, useState } from "react";
import { useAuth } from "../hooks/useAuth";
const PhoneInputCom = ({ onChangePhone, isReset }) => {
  const { user } = useAuth();
  const [value, setValue] = useState(
    user !== null ? "+91" + user.phoneNo : "+91"
  );

  useEffect(() => {
    //  console.log('isreset', isReset)
    if (isReset === true) {
      setValue(user !== null ? "+91" + user.phoneNo : "+91");
    }
  }, [isReset]);

  useEffect(() => {
    setValue(user !== null ? "+91" + user.phoneNo : "+91");
  }, [user]);

  const onChangeInput = (phone) => {
    setValue(phone);
    onChangePhone(phone.replace("+91", ""));
  };

  return (
    <div id="phone-input-container">
      <PhoneInput
        defaultCountry="in"
        value={value}
        onChange={(phone) => onChangeInput(phone)}
        hideDropdown={true}
        forceDialCode={true}
        inputProps={{
          className: "w-100 p-2 ",
          disabled: user !== null ? true : false,
        }}
        countrySelectorStyleProps={{
          buttonStyle: {
            padding: "8px",
            width: "100%",
            height: "100%",
          },
        }}
      />
    </div>
  );
};

export default PhoneInputCom;
