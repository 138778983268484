import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import BackToTop from "../common/BackToTop";
import Footer from "../common/Footer";
import { convertDatetoReadFormat } from "../common/GeneralMethod";
import Header from "../common/Header";
import LoginModal from "../common/LoginModal";
import { useAuth } from "../hooks/useAuth";
import { useBookingData } from "../hooks/useBookingData";
import { useBookNowModal } from "../hooks/useBookNowModal";
import { useLoginModal } from "../hooks/useLoginModal";
import { useLoginStep } from "../hooks/useLoginStep";
import carImage from "../images/creta.jpg";
import BookNow from "./BookNow";
import OtherTermsModal from "./OtherTermsModal";
import { useBookingSelectedDetails } from "../hooks/useBookingSelectedDetails";

const BookingNew = () => {
  const { user } = useAuth();
  const { step, setStep } = useLoginStep();
  const { showBookNowModal, setBookNowModalState } = useBookNowModal();
  const { bookSelectedDetails, setBookSelectedDetails } =
    useBookingSelectedDetails(); //booking selected details
  const { bookData, setBookData } = useBookingData(); // compute results set particualr card data
  const { state } = useLocation();
  // const [bookingData, setBookingData] = useState({}); // booking selected data
  const [bookingResults, setBookingResults] = useState([]); // compute results array data
  const [isLoading, setIsLoading] = useState(false);
  const [isAccessible, setIsAccessible] = useState(true);

  const [showModal, setShowModal] = useState(false);

  const [isBookNow, setIsBookNow] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  useEffect(() => {
    if (state === null) {
      setIsAccessible(false);
      return;
    } else {
      setBookingResults([...state.result]);
    }
  }, [state]);

  useEffect(() => {
    //console.log('setting booking data')
    if (JSON.stringify(bookSelectedDetails) !== "{}") {
      setIsLoading(false);
    }
  }, [bookSelectedDetails]);

  const computeTotalCharges = (
    totalFare,
    toll,
    driverCharges,
    nightCharges
  ) => {
    return (
      parseFloat(totalFare) +
      parseFloat(toll) +
      parseFloat(driverCharges) +
      parseFloat(nightCharges)
    );
  };

  const { showLoginModal, setShowLoginModal } = useLoginModal();

  const closeSignUpModal = () => {
    setShowLoginModal(false);
  };

  const handleBookNowClick = (data) => {
    setIsBookNow(true);
    setBookData({ ...data });
    // console.log(user!==null, window.localStorage.getItem("user") !==null)
    if (user === null || window.localStorage.getItem("user") === null) {
      // console.log('isnide this')
      setStep(2);
      // setBookingData({...bookingData})
      setShowLoginModal(true);
    } else {
      setBookNowModalState(true);
    }
  };
  return (
    <div>
      <Header
        phoneNo={bookSelectedDetails.userPhone}
        isBookNow={isBookNow}
      ></Header>
      {isAccessible ? (
        JSON.stringify(bookSelectedDetails) !== "{}" ? (
          bookingResults.length > 0 ? (
            <main>
              <div className="main-content my-5 py-3">
                <div className="container">
                  <div className="row gap-0">
                    <div className="col-lg-12">
                      <div className="card whitewrap shadow rounded">
                        <div className="card-body p-0">
                          <div className="headings border-bottom p-3">
                            <h4 className="mb-0">Booking Details</h4>
                            <p className="mb-0">
                              {bookSelectedDetails.bookingType === "oneway"
                                ? "One-Way"
                                : "Round Trip"}
                            </p>
                          </div>

                          {/* Booking Details */}
                          <div className="bookingDetails">
                            <div className="roundTrip">
                              <ul>
                                <li>
                                  <p className="mb-0">
                                    {bookSelectedDetails.pickupLocation}
                                  </p>
                                </li>
                                {bookSelectedDetails.intermediates.length > 0 &&
                                  bookSelectedDetails.intermediates.map(
                                    (data, index) => {
                                      return (
                                        <li key={index}>
                                          <p className="mb-0">
                                            {data.interLocation}
                                          </p>
                                        </li>
                                      );
                                    }
                                  )}

                                <li>
                                  <p className="mb-0">
                                    {bookSelectedDetails.dropOffLocation}
                                  </p>
                                </li>
                              </ul>
                            </div>

                            {/* Customer Details */}
                            <div className="custDetails p-3">
                              <div className="row">
                                <div className="col-lg-2">
                                  <div className="fw-bold">Phone Number</div>
                                  <div>
                                    <small>
                                      {"+91 " + bookSelectedDetails.userPhone}
                                    </small>
                                  </div>
                                </div>
                                {bookSelectedDetails.bookingType ===
                                process.env.REACT_APP_BOOKING_TYPE_ONE_WAY ? (
                                  <div className="col-lg-2">
                                    <div className="fw-bold">Estimate Time</div>
                                    <div>
                                      <small>
                                        {bookingResults.length > 0
                                          ? JSON.stringify(
                                              bookingResults[0]
                                                .timeDurationInHour
                                            ) + " Hr"
                                          : 0}
                                      </small>
                                    </div>
                                  </div>
                                ) : null}

                                {/* <div className="col-lg-2">
                                  <div className="fw-bold">Goods/Luggage</div>
                                  <div>
                                    <small>
                                      {bookingResults.length > 0
                                        ? bookingResults[0].luggageAllowed
                                        : "0 bags Allowed"}
                                    </small>
                                  </div>
                                </div> */}
                                <div className="col-lg-2">
                                  <div className="fw-bold">Total Distance</div>
                                  <div>
                                    <small>
                                      {bookingResults.length > 0
                                        ? JSON.stringify(
                                            bookingResults[0].tripDistance
                                          ) + " KM"
                                        : 0}
                                    </small>
                                  </div>
                                </div>
                                {/* <div className="col-lg-2">
                                  <div className="fw-bold">
                                    {"GST Included" +
                                      (bookingResults.length > 0
                                        ? "(" + bookingResults[0].gstRate + "%)"
                                        : "(0%)")}
                                  </div>
                                  <div>
                                    <small>
                                      {bookingResults.length > 0
                                        ? "Rs. " + bookingResults[0].gstAmount
                                        : "Rs. 0"}
                                    </small>
                                  </div>
                                </div> */}
                                <div className="col-lg-2">
                                  <div className="fw-bold">
                                    Pickup Date & Time
                                  </div>
                                  <div>
                                    <small>
                                      {convertDatetoReadFormat(
                                        bookSelectedDetails.pickupTime
                                      )}
                                    </small>
                                  </div>
                                </div>
                                {bookSelectedDetails.bookingType ===
                                process.env.REACT_APP_BOOKING_TYPE_ROUND_WAY ? (
                                  <div className="col-lg-2">
                                    <div className="fw-bold">Drop Date</div>
                                    <div>
                                      <small>
                                        {convertDatetoReadFormat(
                                          bookSelectedDetails.dropOffTime
                                        )}
                                      </small>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </div>

                            {/* Cars Details */}
                            <div className="selectCar p-3 border-top">
                              <h5 className="mb-1">Select Cars</h5>
                              <div className="car-container">
                                <div className="row g-2">
                                  {bookingResults.length > 0 &&
                                    bookingResults.map((data, index) => (
                                      <div
                                        className="col-md-3 col mb-0"
                                        key={index}
                                      >
                                        <div className="car-select card card-body shadow p-0 position-relative">
                                          <div className="p-3">
                                            <div>
                                              <img
                                                src={carImage}
                                                alt={data.vehicleType}
                                              />
                                            </div>
                                          </div>
                                          <div className="price_block">
                                            <div className="default">
                                              <del>
                                                <i className="fa-solid fa-indian-rupee-sign"></i>
                                                <span>
                                                  {" " + data.discountAmount}
                                                </span>
                                              </del>
                                            </div>
                                            <div className="actual">
                                              <i className="fa-solid fa-indian-rupee-sign"></i>
                                              <span>
                                                {" " + data.totalFare}
                                              </span>
                                            </div>
                                          </div>
                                          <h6 className="text-center">
                                            {data.vehicleType}
                                          </h6>
                                          <p className="mb-0 type_list">
                                            {data.vehicleName}
                                          </p>
                                          <div className="price_details">
                                            <ul className="price_break">
                                              {/* <li>
                                                <div>Capacity</div>
                                                <div>
                                                  {data.vehicleSeaterCount}
                                                </div>
                                              </li> */}
                                              <li>
                                                <div>Extra fare/Km</div>
                                                <div>
                                                  <i className="fa-solid fa-indian-rupee-sign"></i>
                                                  <span>
                                                    {" " +
                                                      data.ratePerKm +
                                                      "/Km"}
                                                  </span>
                                                </div>
                                              </li>
                                              <li>
                                                <div>Toll, State Tax</div>
                                                <div>
                                                  {/* <i className="fa-solid fa-indian-rupee-sign"></i> */}
                                                  <span>
                                                    {data.tollTaxAmount === 0
                                                      ? "Included"
                                                      : data.tollTaxAmount}
                                                  </span>
                                                </div>
                                              </li>
                                              <li>
                                                <div>Driver Charges</div>
                                                <div>
                                                  {data.driverChargesAmount ===
                                                  0
                                                    ? "Included"
                                                    : data.driverChargesAmount}
                                                </div>
                                              </li>
                                              <li>
                                                <div>Night Charges</div>
                                                <div>
                                                  {data.nightChargesAmount === 0
                                                    ? "Included"
                                                    : data.nightChargesAmount}
                                                </div>
                                              </li>
                                              {/* <li>
                                                <div>Total Charges(Rs.)</div>
                                                <div>{data.totalFare}</div>
                                              </li> */}
                                              {/* <li>
                                                <div>
                                                  {"Discount(" +
                                                    data.discountPercent +
                                                    "%)"}
                                                </div>
                                                <div>{data.discountAmount}</div>
                                              </li> */}
                                            </ul>
                                            <a
                                              className="otherTerms"
                                              onClick={handleShow}
                                            >
                                              Other Terms
                                            </a>
                                          </div>
                                          <div className="book_now">
                                            <a
                                              className="btn btn-dark mb-0"
                                              onClick={() =>
                                                handleBookNowClick(data)
                                              }
                                            >
                                              Book Now
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <LoginModal
                show={showLoginModal}
                handleClose={closeSignUpModal}
                phoneNo={bookingData.userPhone}
              ></LoginModal> */}
            </main>
          ) : (
            <h5>No Cars Available</h5>
          )
        ) : (
          <h5>Oops! Something Went Wrong</h5>
        )
      ) : (
        <h5>Oops! Something Went Wrong</h5>
      )}
      <BookNow setIsBookNow={setIsBookNow}></BookNow>

      <Footer
        popularCities={state.popularCities}
        popularCitiesNames={state.popularCitiesNames}
      ></Footer>
      <BackToTop></BackToTop>

      <OtherTermsModal show={showModal} handleClose={handleClose} />
    </div>
  );
};

export default BookingNew;
