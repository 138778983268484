import React from "react";

import "@fortawesome/fontawesome-free/css/all.min.css";
import { Modal, Button } from "react-bootstrap";
import { usePage } from "../hooks/usePage";

const DeleteConfirmationModal = ({
  message,
  show,
  handleClose,
  handleDeleteUser,
}) => {
  const { page, setPage } = usePage();
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      dialogClassName="success-modal"
    >
      <Modal.Body>
        <div style={styles.container}>
          <div style={styles.icon}>
            <i className="fas fa-times-circle text-danger"></i>
          </div>

          <h6 className="lead">{message}</h6>
          <div>
            <a
              className="btn btn-custom btn-home"
              style={{ ...styles.btn, ...styles.btnHome }}
              onClick={() => handleClose()}
            >
              No
            </a>
            <a
              className="btn btn-custom btn-details"
              style={{ ...styles.btn, ...styles.btnDetails }}
              onClick={handleDeleteUser}
            >
              Yes
            </a>
          </div>
        </div>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer> */}
    </Modal>
  );
};

const styles = {
  container: {
    textAlign: "center",
    background: "#ffffff",
    padding: "40px",
    borderRadius: "8px",
    boxShadow: "0 0 15px rgba(0,0,0,0.1)",
  },
  icon: {
    fontSize: "80px",
    color: "#d6293e",
    marginBottom: "20px",
  },
  btn: {
    margin: "10px",
    padding: "10px 20px",
    fontSize: "16px",
  },
  btnHome: {
    backgroundColor: "#0cbc87",
    color: "#fff",
  },
  btnDetails: {
    backgroundColor: "#d6293e",
    color: "#fff",
  },
};

export default DeleteConfirmationModal;
