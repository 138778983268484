// import React from 'react';
// import { Modal, Button } from 'react-bootstrap';

// const SuccessModal = ({ show, handleClose, handleManageBooking }) => {
//   return (
//     <Modal show={show} onHide={handleClose} centered>
//       {/* <Modal.Header closeButton>
//         <Modal.Title>Success</Modal.Title>
//       </Modal.Header> */}
//       <Modal.Body>
//         <div className="text-center">
//           <i className="bi bi-check-circle text-success" style={{ fontSize: '3rem' }}></i>
//           <h5 className="mt-3">Booking Successful!</h5>
//           <p>Your booking will be shared soon via SMS or emai.</p>
//         </div>
//       </Modal.Body>
//       <Modal.Footer>
//         <Button variant="secondary" onClick={handleManageBooking} className="mx-2">
//           Manage Booking
//         </Button>
//         <Button variant="secondary" onClick={handleClose} className="mx-2">
//           OK
//         </Button>
//       </Modal.Footer>
//     </Modal>
//   );
// };

// export default SuccessModal;

import React from "react";

import "@fortawesome/fontawesome-free/css/all.min.css";
import { Modal, Button } from "react-bootstrap";
import { usePage } from "../hooks/usePage";
import { useBookingSelectedDetails } from "../hooks/useBookingSelectedDetails";

const BookingSuccess = ({ show, handleClose, handleManageBooking }) => {
  const { page, setPage } = usePage();
  const { bookSelectedDetails, setBookSelectedDetails } =
    useBookingSelectedDetails(); //booking selected details
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      dialogClassName="success-modal"
    >
      <Modal.Body>
        <div style={styles.container}>
          <div style={styles.icon}>
            <i className="fas fa-check-circle"></i>
          </div>
          <h1 className="text-success">Booking Successful!</h1>
          <p className="lead">
            Thank you for booking with us. Your reservation has been
            successfully completed.
          </p>
          <div>
            <a
              className="btn btn-custom btn-home"
              style={{ ...styles.btn, ...styles.btnHome }}
              onClick={() => {
                setBookSelectedDetails({});
                setPage("Home");
              }}
            >
              Go to Home
            </a>
            <a
              className="btn btn-custom btn-details"
              style={{ ...styles.btn, ...styles.btnDetails }}
              onClick={handleManageBooking}
            >
              View Booking Details
            </a>
          </div>
        </div>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer> */}
    </Modal>
  );
};

const styles = {
  container: {
    textAlign: "center",
    background: "#ffffff",
    padding: "40px",
    borderRadius: "8px",
    boxShadow: "0 0 15px rgba(0,0,0,0.1)",
  },
  icon: {
    fontSize: "80px",
    color: "#28a745",
    marginBottom: "20px",
  },
  btn: {
    margin: "10px",
    padding: "10px 20px",
    fontSize: "16px",
  },
  btnHome: {
    backgroundColor: "#ffa025",
    color: "#fff",
  },
  btnDetails: {
    backgroundColor: "#6c757d",
    color: "#fff",
  },
};

export default BookingSuccess;
