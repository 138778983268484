import { createContext, useContext, useState } from "react";

const InternetConnectionContext = createContext();
export const InternetConnectionProvider = ({ children }) => {
  const [show, setShow] = useState(false);

  return (
    <InternetConnectionContext.Provider value={{ show, setShow }}>
      {children}
    </InternetConnectionContext.Provider>
  );
};

export const useInternetConnection = () => {
  return useContext(InternetConnectionContext);
};
