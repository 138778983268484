import axios from "axios";
import debounce from "lodash.debounce";
import { useEffect, useRef, useState } from "react";
import Autocomplete from "react-autocomplete";
import { NotificationManager } from "react-notifications";
import { ApiErrorMessage, ApiHeader } from "../common/ConstStates";
import { callApi } from "../common/GeneralMethod";
import { useLoading } from "../hooks/useLoading";
import { useBookingSelectedDetails } from "../hooks/useBookingSelectedDetails";

const DropDownInputBox = ({
  placeholder,
  label,
  handleOnSelectLocation,
  name,
  keyValue,
  isReset,
  selectedValue,
}) => {
  useEffect(() => {
    // console.log("isreset", isReset);
    if (isReset === true) {
      setValue("");
    }
  }, [isReset]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [locationList, setLocationList] = useState([]);
  const [value, setValue] = useState(selectedValue);
  const { startLoading, stopLoading } = useLoading();
  //const [loading, setLoading] = useState(false);
  const axiosSource = useRef(null);

  const fetchLocation = async (searchValue) => {
    if (axiosSource.current) {
      axiosSource.current.cancel("Operation canceled due to new request.");
    }
    axiosSource.current = axios.CancelToken.source();
    if (searchValue === "") {
      stopLoading();
      setLocationList([]);
      setShowDropdown(false);
      return;
    }

    // startLoading();
    const response = await callApi(
      "get",
      "Booking/placesPredictions?input=" + searchValue,
      {},
      { ...ApiHeader }
    );

    if (response !== undefined && response !== null) {
      //    console.log(response);

      if (response.data.code === 200) {
        setShowDropdown(true);
        setLocationList([...response.data.data]);
      } else {
        setLocationList([]);
        setShowDropdown(false);
        //NotificationManager.warning("No location Found");
      }
      // stopLoading();
    } else {
      NotificationManager.error(ApiErrorMessage);
      // stopLoading();
    }
  };
  const handleOnChange = (value) => {
    setValue(value);
  };

  const debouncedFetchData = useRef(
    debounce((query) => {
      fetchLocation(query);
    })
  ).current;

  useEffect(() => {
    debouncedFetchData(value);
  }, [value, debouncedFetchData]);

  const handleOnSelect = async (value) => {
    // console.log(keyValue)
    const selectedLocation = locationList.find(
      (data, index) => data.placeId === value
    );
    handleOnSelectLocation(selectedLocation, keyValue);
    setValue(selectedLocation.text);
    setShowDropdown(false);
  };

  return (
    <div
      key={keyValue}
      className="form-fs-lg form-control-transparent d-flex align-items-start flex-column"
      id={keyValue}
    >
      <label className="form-label small ml-0">{label}</label>
      <Autocomplete
        getItemValue={(item) => item.placeId}
        items={showDropdown ? locationList : []}
        renderItem={(item, isHighlighted) => (
          <div
            key={item.placeId}
            style={{
              padding: "5px",
              background: isHighlighted ? "lightgray" : "white",
            }}
          >
            {item.text}
          </div>
        )}
        onMenuVisibilityChange={(isOpen) => {
          setShowDropdown(isOpen);
        }}
        value={value}
        name={name}
        onChange={(e) => {
          handleOnChange(e.target.value);
        }}
        onSelect={(val) => {
          handleOnSelect(val);
        }}
        menuStyle={{
          borderRadius: "3px",
          boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
          background: "rgba(255, 255, 255, 0.9)",
          padding: "2px 0",
          fontSize: "90%",
          position: "fixed",
          overflow: "auto",
          maxHeight: "50%",
          zIndex: "999",
          width: "200px",
          cursor: "pointer",
        }}
        inputProps={{
          className: "form-control form-control-sm border border-black",
          placeholder: placeholder,
        }}
        wrapperStyle={{
          width: "100%",
        }}
      />
    </div>
  );
};

export default DropDownInputBox;
