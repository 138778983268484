import { BsFillSendFill } from "react-icons/bs";
import { CiLocationOn, CiPhone } from "react-icons/ci";
import bannerImg from "../images/inner.webp";
import { useState } from "react";
import { NotificationManager } from "react-notifications";
import {
  ApiHeaders,
  ContactUsFormData,
  emailRegex,
  ValidationErrorMessage,
} from "../common/ConstStates";
import { useLoading } from "../hooks/useLoading";
import { callApi } from "../common/GeneralMethod";
const Contact = () => {
  const { startLoading, stopLoading } = useLoading();
  const errorMessage = "An error occurred while sending the message.";
  const [formData, setFormData] = useState({ ...ContactUsFormData });

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const validation = () => {
    let count = 0;

    if (formData.senderName === "") count++;
    if (formData.emailId === "") count++;
    if (formData.subject === "") count++;
    if (formData.message === "") count++;
    if (count > 0) {
      return ValidationErrorMessage;
    } else if (!emailRegex.test(formData.emailId)) {
      return "Invalid Email";
    }
    return "";
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Check validations
    const validationMsg = validation();
    if (validationMsg !== "") {
      NotificationManager.warning(validationMsg);
      return;
    }
    startLoading();
    const response = await callApi(
      "post",
      "TermsAndPolicies/ContactUs",
      { ...formData },
      { ...ApiHeaders }
    );
    if (response !== undefined && response !== null) {
      if (response.data.code === 200) {
        NotificationManager.success("Message Sent Successfully");
      } else {
        NotificationManager.error(errorMessage);
      }
    } else {
      NotificationManager.error(errorMessage);
    }
    stopLoading();
    setFormData({ ...ContactUsFormData });
  };
  return (
    <div>
      <main>
        {/* Banner Start */}
        <div className="innerBanner position-relative">
          <img src={bannerImg} alt="about us" />
          <div className="overlay">
            <div className="d-flex flex-column align-items-center justify-content-center py-6">
              <div className="breadcrumbs">
                <a className="breadcrumbs_item home" href="/">
                  Home
                </a>
                <span className="breadcrumbs_delimiter">/</span>
                <span className="breadcrumbs_item current">Contact Us</span>
              </div>
              <div className="page_title">
                <h1 className="page_caption text-white">Contact Us</h1>
              </div>
            </div>
          </div>
        </div>
        {/* Banner End */}

        <div className="main-content my-5 py-3">
          <div className="container">
            <div className="contact-wrap rounded-2">
              <div className="row g-0">
                <div className="col-lg-8 col-md-7 order-md-last d-flex align-items-stretch">
                  <div className="contact-wrap w-100 p-md-5 p-4">
                    <h3 className="mb-3">Get In Touch</h3>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control border-top-0 border-end-0 border-start-0 rounded-0 no-focus"
                            id="senderName"
                            placeholder="Enter your Name"
                            value={formData.senderName}
                            onChange={handleInputChange}
                            required
                          />
                          <label htmlFor="name">
                            Name<span style={{ color: "red" }}>*</span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <div className="form-floating">
                          <input
                            type="email"
                            className="form-control border-top-0 border-end-0 border-start-0 rounded-0 no-focus"
                            id="emailId"
                            placeholder="Enter your email"
                            value={formData.emailId}
                            onChange={handleInputChange}
                            required
                          />
                          <label htmlFor="email">
                            Email<span style={{ color: "red" }}>*</span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control border-top-0 border-end-0 border-start-0 rounded-0 no-focus"
                            id="subject"
                            placeholder="Subject"
                            value={formData.subject}
                            onChange={handleInputChange}
                            required
                          />
                          <label htmlFor="subject">
                            Subject<span style={{ color: "red" }}>*</span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="form-floating">
                          <textarea
                            className="form-control border-top-0 border-end-0 border-start-0 rounded-0 no-focus"
                            placeholder="Leave a comment here"
                            id="message"
                            style={{ height: "50px" }}
                            value={formData.message}
                            onChange={handleInputChange}
                            required
                          ></textarea>
                          <label htmlFor="comments">
                            Comments<span style={{ color: "red" }}>*</span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          onClick={handleSubmit}
                        >
                          Send Message
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-5 d-flex align-items-stretch">
                  <div className="info-wrap topnav w-100 p-md-5 p-4">
                    <h3 className="mb-3 text-white">Let's get in touch</h3>
                    <p className="text-white">
                      We're open for any suggestion or just to have a chat
                    </p>
                    <div className="w-100 d-flex align-items-start mb-4">
                      <div className="addressLinks d-flex align-items-center justify-content-center text-white fs-5">
                        <CiLocationOn />
                      </div>
                      <div className="text-address ps-3">
                        Address:  MATHURA, UTTAR PRADESH
                      </div>
                    </div>
                    <div className="w-100 d-flex align-items-center mb-4">
                      <div className="addressLinks d-flex align-items-center justify-content-center text-white fs-5">
                        <CiPhone />
                      </div>
                      <div className="text-address ps-3">
                        Phone: +91 9997325252
                      </div>
                    </div>
                    <div className="w-100 d-flex align-items-center mb-4">
                      <div className="addressLinks d-flex align-items-center justify-content-center text-white fs-5">
                        <BsFillSendFill />
                      </div>
                      <div className="text-address ps-3">
                        Email: contactus@cabkro.com
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Contact;
