import React, { useEffect, useState } from "react";
import { Button, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { NotificationManager } from "react-notifications";
import OtpInputBox from "../Components/OtpInputBox";
import PhoneInputCom from "../Components/PhoneInput";
import { callApi, setUserData } from "../common/GeneralMethod";
import { useAuth } from "../hooks/useAuth";
import { useBookNowModal } from "../hooks/useBookNowModal";
import { useBookingData } from "../hooks/useBookingData";
import { useLoading } from "../hooks/useLoading";
import { useLoginStep } from "../hooks/useLoginStep";
import loginImg from "../images/car-pop.png";
import {
  ApiHeader,
  ApiHeaders,
  emailRegex,
  indianPhoneRegex,
  MaxTodayDate,
  ValidationErrorMessage,
} from "./ConstStates";

const LoginModal = ({ show, handleClose, phoneNo, isBookNow }) => {
  const { step, setStep } = useLoginStep();
  const { login } = useAuth();
  const { startLoading, stopLoading } = useLoading();
  const { showBookNowModal, setBookNowModalState } = useBookNowModal();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState(0);
  const [isFormDataReset, setIsFormDataReset] = useState(false);
  const [response, setResponse] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [isLoginWithPassword, setIsLoginWithPassword] = useState(false);
  const [loginPassword, setLoginPassword] = useState("");
  useEffect(() => {
    // console.log("inside here");
    //Reset form data
    setIsFormDataReset(true);
    //Set phone no field to the prop phone no
    setPhoneNumber(phoneNo);
    setOtp(0);
  }, []);

  useEffect(() => {
    if (step === 2 && phoneNo.length === 10) {
      setPhoneNumber(phoneNo);
      // console.log("inside here", phoneNo);
      sendOtp(phoneNo);
    }
  }, [step, phoneNo]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleCloseModal = () => {
    setStep(1);
    setPhoneNumber("");
    setOtp(0);
    setResponse({});
    setIsLoginWithPassword(false);
    handleClose();
  };

  const onChangePhoneInput = (value) => {
    setPhoneNumber(value);
  };

  const handleLoginInClick = () => {
    // console.log(phoneNumber);
    if (phoneNumber === "+91") {
      NotificationManager.error("Please Enter Phone no");
      return;
    }
    if (phoneNumber.length < 10) {
      NotificationManager.error("Invalid Phone No");
      return;
    }

    if (!indianPhoneRegex.test(phoneNumber)) {
      NotificationManager.error("Invalid Phone No");
      return;
    }
    // Send otp on provided number
    sendOtp(phoneNumber);
  };

  const sendOtp = async (number) => {
    startLoading();
    //   console.log(phoneNumber);
    const sendOtpResponse = await callApi(
      "get",
      "Auth/SendOtp?phone=" + number,
      {},
      { ...ApiHeaders }
    );
    //Check otp response is not null
    if (sendOtpResponse !== undefined && sendOtpResponse !== null) {
      //check response is success
      if (sendOtpResponse.data.code === 200) {
        setResponse({ ...sendOtpResponse.data });
        NotificationManager.success("OTP Sent Successfully");
        //show second step
        setStep(2);
        stopLoading();
      } else {
        NotificationManager.error("An error occurred while sending the OTP.");
        stopLoading();
      }
    } else {
      NotificationManager.error("An error occurred while sending the OTP.");
      stopLoading();
    }
  };

  const onChangeOtpInput = (value) => {
    setOtp(value);
  };

  const handleLoginPasswordChange = (e) => {
    setLoginPassword(e.target.value);
  };

  const veriftOtpValidations = () => {
    //check login with password
    if (isLoginWithPassword) {
      if (loginPassword === "") {
        return "Please Enter Password";
      } else {
        return "";
      }
    }
    //login with otp
    else {
      if (otp.length < 4) {
        return "Please Enter OTP";
      } else {
        return "";
      }
    }
  };

  const handleVerifyOtpClick = () => {
    const error = veriftOtpValidations();
    if (error !== "") {
      NotificationManager.error(error);
      return;
    }

    //Verify entered otp
    verifyOtp();
  };

  const verifyOtp = async () => {
    startLoading();
    const verifyOtpResponse = await callApi(
      "post",
      "Auth/VerifyOTP",
      {
        userType: response.userType,
        otp: otp === "" ? 0 : parseInt(otp),
        phoneNo: phoneNumber,
        password: loginPassword,
      },
      { ...ApiHeaders }
    );

    if (verifyOtpResponse !== undefined && verifyOtpResponse !== null) {
      const result = verifyOtpResponse.data;
      if (result.code === 200) {
        if (result.isRegister === false) {
          setStep(3);
          setResponse({ ...result.data });
        } else {
          handleCloseModal();
          const userData = setUserData({ ...result.data });
          login({ ...userData });
          // Getting modal from booking page
          if (isBookNow && phoneNo !== "") {
            //Open final booking page
            setBookNowModalState(true);
          }
        }
        stopLoading();
        NotificationManager.success("Login Successfully");
      } else {
        stopLoading();
        NotificationManager.error("Incorrect crendentials");
      }
    } else {
      stopLoading();
      NotificationManager.error("Error while processing request");
    }
  };

  const handleRegisterInputChange = (e) => {
    setResponse({ ...response, [e.target.name]: e.target.value });
  };

  const registerValidations = () => {
    let count = 0;
    if (response.userFirstName === null || response.userFirstName === "")
      count++;
    if (response.userLastName === null || response.userLastName === "") count++;
    if (response.userEmail === null || response.userEmail === "") count++;
    if (response.dob === null || response.dob === "") count++;
    if (response.gender === 0 || response.gender === "") count++;

    if (count > 0) {
      return ValidationErrorMessage;
    }

    if (!emailRegex.test(response.userEmail)) {
      return "Invalid Email";
    }

    return "";
  };

  const registerUser = async (e) => {
    e.preventDefault();
    const error = registerValidations();
    if (error !== "") {
      NotificationManager.warning(error);
      return;
    }
    startLoading();
    const registerResponse = await callApi(
      "post",
      "Auth/RegisterUser",
      {
        ...response,
        phoneNo: phoneNumber,
        createdDate: new Date().toISOString(),
      },
      { ...ApiHeaders }
    );
    if (registerResponse !== undefined && registerResponse !== null) {
      const result = registerResponse.data;
      if (result.isRegister === true) {
        const userData = setUserData({ ...result.data });
        login({ ...userData });

        setBookNowModalState(true);
        NotificationManager.success("User Register Successfully");
        handleCloseModal();
        stopLoading();
      } else {
        NotificationManager.error(result.message);
        stopLoading();
      }
    } else {
      NotificationManager.error("Error while User Registration");
      stopLoading();
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        size="lg"
        backdrop="static"
        keyboard={false}
        className="rounded-modal"
      >
        <Modal.Body className="p-0 overflow-hidden">
          <Row>
            {/* Left side image */}
            <Col md={6}>
              <div className="loginImg h-100 p-3">
                <img
                  src={loginImg}
                  alt="login"
                  className="img-fluid avatar-img"
                />
              </div>
            </Col>
            {/* Right side UI */}
            <Col md={5}>
              {/* Show phone no input screen */}
              {step === 1 && (
                <div className="loginContainer px-1 py-5">
                  <h5 className="mb-2 text-uppercase text-secondary fw-normal">
                    Log In
                  </h5>
                  <p>Enter your credential to access your account</p>
                  <Form>
                    <Form.Group className="mb-4">
                      <Form.Label>Phone Number</Form.Label>
                      <PhoneInputCom
                        onChangePhone={onChangePhoneInput}
                        isReset={isFormDataReset}
                      ></PhoneInputCom>
                    </Form.Group>
                    <Button
                      variant="primary"
                      className="w-100 mb-2"
                      onClick={handleLoginInClick}
                    >
                      Continue
                    </Button>
                  </Form>
                </div>
              )}
              {step === 2 && (
                <div className="loginContainer px-1 py-5">
                  <h5 className="mb-2 text-uppercase text-secondary fw-normal">
                    Log In
                  </h5>
                  <p>{"Enter OTP send to +91 " + phoneNumber}</p>
                  <Form>
                    {isLoginWithPassword ? (
                      <Form.Group className="mb-3">
                        <Form.Label>Enter Password</Form.Label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            type={showPassword ? "text" : "password"}
                            placeholder=""
                            onChange={handleLoginPasswordChange}
                            required
                          />
                          <InputGroup.Text
                            variant="outline-secondary"
                            onClick={togglePasswordVisibility}
                          >
                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                          </InputGroup.Text>
                        </InputGroup>
                      </Form.Group>
                    ) : (
                      <Form.Group className="mb-3">
                        <Form.Label>Enter OTP</Form.Label>
                        <OtpInputBox
                          setValue={onChangeOtpInput}
                          isReset={isFormDataReset}
                        ></OtpInputBox>
                      </Form.Group>
                    )}

                    <Button
                      variant="primary"
                      className="w-100 mb-2"
                      onClick={handleVerifyOtpClick}
                    >
                      Submit
                    </Button>
                    {/* Show login with password when user is register */}
                    {response.isRegister ? (
                      <Form.Group style={{ textAlign: "end" }}>
                        <Form.Label className="text-left">
                          Or Login With{" "}
                          <span
                            className="text-orange"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setIsLoginWithPassword(!isLoginWithPassword);
                              setOtp("");
                              setLoginPassword("");
                            }}
                          >
                            {isLoginWithPassword ? "OTP" : "Password"}
                          </span>
                        </Form.Label>
                      </Form.Group>
                    ) : null}
                  </Form>
                </div>
              )}
              {/* Register screen */}
              {step === 3 && (
                <div className="loginContainer px-1 py-5">
                  <h5 className="mb-2 text-uppercase text-secondary fw-normal">
                    Register
                  </h5>
                  <Form onSubmit={registerUser}>
                    <Row>
                      <Form.Group as={Col} className="mb-3">
                        <Form.Label>
                          First Name<span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          name="userFirstName"
                          onChange={handleRegisterInputChange}
                        />
                      </Form.Group>{" "}
                      <Form.Group as={Col} className="mb-3">
                        <Form.Label>
                          Last Name<span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          name="userLastName"
                          onChange={handleRegisterInputChange}
                        />
                      </Form.Group>
                    </Row>

                    <Form.Group className="mb-3">
                      <Form.Label>
                        Email address<span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        type="email"
                        placeholder=""
                        name="userEmail"
                        onChange={handleRegisterInputChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        DOB<span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        type="date"
                        placeholder=""
                        name="dob"
                        onChange={handleRegisterInputChange}
                        max={MaxTodayDate}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Password<span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <InputGroup className="mb-3">
                        <Form.Control
                          type={showPassword ? "text" : "password"}
                          placeholder=""
                          name="password"
                          onChange={handleRegisterInputChange}
                        />
                        <InputGroup.Text
                          variant="outline-secondary"
                          onClick={togglePasswordVisibility}
                        >
                          {showPassword ? <FaEyeSlash /> : <FaEye />}
                        </InputGroup.Text>
                      </InputGroup>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>
                        Gender<span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <div key={`inline-radio`} className="mb-3">
                        <Form.Check
                          inline
                          label="Male"
                          type={"radio"}
                          id={`inline-radio-1`}
                          value={1}
                          name="gender"
                          onChange={handleRegisterInputChange}
                        />
                        <Form.Check
                          inline
                          label="Female"
                          type={"radio"}
                          id={`inline-radio-2`}
                          value={2}
                          name="gender"
                          onChange={handleRegisterInputChange}
                        />
                      </div>
                    </Form.Group>
                    <Button
                      type="sumbit"
                      variant="primary"
                      className="w-100 mb-2"
                    >
                      Register
                    </Button>
                  </Form>
                </div>
              )}
            </Col>
          </Row>
          <Button
            variant="secondary"
            onClick={handleCloseModal}
            className="btn-close position-absolute closeModal"
            style={{ top: "10px", right: "10px" }}
          ></Button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LoginModal;
