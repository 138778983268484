import React, { useState } from "react";
import { useAuth } from "../hooks/useAuth";
import { callApi, setUserData } from "../common/GeneralMethod";
import { NotificationManager } from "react-notifications";
import { ApiHeaders } from "../common/ConstStates";
import { useLoading } from "../hooks/useLoading";

const ChangePassword = () => {
  const { user, login } = useAuth();
  const { startLoading, stopLoading } = useLoading();
  const [formData, setFormData] = useState({ ...user });
  const [password, setPassword] = useState("");
  const [reTypePassword, setReTypePassword] = useState("");

  const handlePswdChange = (e) => {
    setPassword(e.target.value);
  };

  const handleRetypeChange = (e) => {
    setReTypePassword(e.target.value);
  };

  const handleChangePswdClick = async (e) => {
    e.preventDefault();
    if(password===''){
      NotificationManager.warning("Please enter password");
      return;
    }
    else if(reTypePassword===''){
      NotificationManager.warning("Please enter confirm password");
      return;
    }
    else if (password !== reTypePassword) {
      NotificationManager.warning("Incorrect Confirm password");
      return;
    }
    
    startLoading();
    formData.password = password;
    const response = await callApi(
      "post",
      "Auth/UpdateUser",
      { ...formData },
      { ...ApiHeaders }
    );
    if (response !== undefined && response !== null) {
      if (response.data.code === 200) {
        const userData = setUserData({ ...response.data.data });
        login({ ...userData });

        //login({ ...response.data.data });
        setPassword("");
        setReTypePassword("");
        NotificationManager.success("Password Updated Successfully");
      } else {
        NotificationManager.error("Error while processing request");
      }
      stopLoading();
    } else {
      NotificationManager.error("Error while processing request");
      stopLoading();
    }
  };
  return (
    <div className="card border mb-3">
      <div className="card-header border-bottom">
        <h4 className="card-header-title">Change Password</h4>
      </div>
      <div className="card-body">
        <form className="row g-3">
          {/* <div className="col-md-6">
            <label className="form-label">Current password<span className="text-danger">*</span></label>
            <input type="password" className="form-control" placeholder="Enter current password" />
          </div> */}
          <div className="col-md-6">
            <label className="form-label">
              New password<span className="text-danger">*</span>
            </label>
            <input
              type="password"
              className="form-control"
              placeholder="Enter new password"
              name="password"
              value={password}
              onChange={handlePswdChange}
            />
          </div>
          <div className="col-md-6">
            <label className="form-label">
              Confirm new password<span className="text-danger">*</span>
            </label>
            <input
              type="password"
              className="form-control"
              placeholder="Confirm new password"
              name="retype"
              value={reTypePassword}
              onChange={handleRetypeChange}
            />
          </div>
          <div className="col-12 text-end">
            <button
              className="btn btn-dark mb-0"
              onClick={handleChangePswdClick}
            >
              Change Password
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;
