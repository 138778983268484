import React from "react";
import { useAuth } from "../hooks/useAuth";
import { usePage } from "../hooks/usePage";
import { useProfileSection } from "../hooks/useProfileSection";
import profileImg from "../images/slider/imageprofile.webp";
import { NotificationManager } from "react-notifications";
const Sidebar = () => {
  const { activeTab, setActiveTab } = useProfileSection();
  const { page, setPage } = usePage();

  const { user, logout } = useAuth();
  const handleLogout = () => {
    logout();
    NotificationManager.success("Logout Successfully");
    setPage("Home");
  };
  return (
    <div className="col-lg-4 col-xl-3">
      <div className="offcanvas-lg offcanvas-end" id="offcanvasSidebar">
        <div className="offcanvas-header justify-content-end pb-2">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body p-3 p-lg-0">
          <div className="card bg-light w-100">
            {/* <div className="position-absolute top-0 end-0 p-3">
              <a className="text-primary-hover" data-bs-toggle="tooltip" data-bs-title="Edit profile" style={{cursor:"pointer"}}>
                <i className="bi bi-pencil-square"></i>
              </a>
            </div> */}
            <div className="card-body p-3">
              <div className="text-center mb-3">
                <div className="avatar avatar-xl mb-2">
                  <img
                    className="avatar-img rounded-circle border border-2 border-white"
                    src={user.userImage === null ? profileImg : user.userImage}
                    alt="Avatar"
                  />
                </div>
                <h6 className="mb-0">
                  {user.userFirstName + " " + user.userLastName}
                </h6>
                <a className="text-reset text-primary-hover small">
                  {user.userEmail}
                </a>
                <hr />
              </div>
              <ul className="nav nav-pills-primary-soft flex-column">
                <li className="nav-item">
                  <a
                    className="nav-link"
                    style={{ cursor: "pointer" }}
                    onClick={() => setActiveTab(1)}
                  >
                    <i className="bi bi-person fa-fw me-2"></i>My Profile
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    style={{ cursor: "pointer" }}
                    onClick={() => setActiveTab(2)}
                  >
                    <i className="bi bi-ticket-perforated fa-fw me-2"></i>My
                    Bookings
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    style={{ cursor: "pointer" }}
                    onClick={() => setActiveTab(3)}
                  >
                    <i className="bi bi-trash fa-fw me-2"></i>Delete Profile
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link text-danger bg-danger-soft-hover"
                    style={{ cursor: "pointer" }}
                    onClick={handleLogout}
                  >
                    <i className="fas fa-sign-out-alt fa-fw me-2"></i>Sign Out
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
