import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useInternetConnection } from "../hooks/useInternetConnection";

const InternetConnectionModal = () => {
  const { show, setShow } = useInternetConnection(false);

  const handleClose = () => {
    setShow(false);
  };
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Connection Issue</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Please check your internet connection or try again later.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default InternetConnectionModal;
